<template>
  <section class="d-flex flex-column justify-content-center align-items-center">
    <img
      class="mx-auto"
      src="@/assets/images/coming_soon.png"
      alt="loading icon"
      width="168"
      height="170"
    />
    <p class="text-center fw-bold">Coming Soon!</p>
    <small class="text-center">
      Stay tuned while we keep you up to date on our progress.
    </small>
  </section>
</template>
<script>
export default {
  name: "Dashboard",
};
</script>
<style scoped>
section {
  height: 90vh;
}
</style>
