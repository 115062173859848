<template>
  <nav id="sideNav">
    <div class="py-4">
      <div class="d-flex mt-4 ms-4">
        <img
          class="rounded-circle"
          width="36"
          height="36"
          src="@/assets/images/null_female.png"
          alt="user image"
        />
        <div class="ms-3">
          <h6 class="text-white m-0 mt-1">{{ username }}</h6>
          <small class="">{{ userEmail }}</small>
        </div>
      </div>

      <router-link to="/app" class="d-flex mt-5 nav-link-item">
        <span class="fw-bold text-white">Dashboard</span>
      </router-link>

      <router-link to="/change-password" class="d-flex nav-link-item">
        <span class="fw-bold text-white">Change Password</span>
      </router-link>

      <div class="d-flex nav-link-btn align-items-center">
        <button @click="logoutUser" class="btn d-flex">
          <i class="fas fa-sign-out-alt text-white mt-1"></i>
          <p class="fw-bold text-white ms-2">Logout</p>
        </button>
      </div>
    </div>
  </nav>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "SideNav",

  computed: {
    ...mapGetters(["username", "userEmail"]),
  },

  methods: {
    ...mapActions(["logout"]),
    logoutUser() {
      this.$router.push({ path: "/" });

      this.logout();
    },
  },
};
</script>
<style scoped>
#sideNav {
  background: #03435f;
  height: calc(100vh - 65px);
  width: 0;
  position: absolute;
  left: 0px;
  overflow-x: hidden;
  transition: 0.5s;
}
a {
  display: block;
  text-decoration: none;
}
button:hover {
  outline: none;
  box-shadow: none;
}
#sideNav h6 {
  line-height: 10px;
}
#sideNav small {
  color: #bfb8b8;
}
#sideNav > div > div:last-child {
  position: absolute;
  bottom: 0;
}
.nav-link-btn {
  width: 100%;
}
.nav-link-btn .btn {
  padding: 1rem 2rem;
  background: transparent;
}
a.nav-link-item:link {
  padding: 1.3rem 2rem;
  background: transparent;
}
a.nav-link-item:visited,
a.nav-link-item:hover,
a.nav-link-item:active {
  background: #013b54 !important;
}
/*Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  #sideNav {
    width: 300px;
  }
}
</style>
