<template>
  <section class="d-flex justify-content-center rounded px-3 pb-5 pt-4">
    <div class="coln">
      <h4 class="mb-4 mt-3">Change Password</h4>

      <div class="mt-4">
        <label for="oldPassword">
          <span class="text-danger">*</span>
          Old Password
        </label>
        <input
          autocomplete="off"
          id="oldPassword"
          v-model="form.oldPassword"
          class="form-control"
          type="password"
          placeholder="+6 Characters"
        />
      </div>

      <div class="mt-4">
        <label for="newPassword">
          <span class="text-danger">*</span>
          New Password
        </label>
        <input
          autocomplete="off"
          id="newPassword"
          v-model="form.newPassword"
          class="form-control"
          type="password"
          placeholder="+6 Characters"
        />
      </div>

      <div class="mt-4">
        <label for="cpassword">
          <span class="text-danger">*</span>
          Re-enter New Password
        </label>
        <input
          autocomplete="off"
          id="cpassword"
          v-model="form.cpassword"
          class="form-control"
          type="password"
          placeholder="+6 Characters"
        />
      </div>
      <small v-if="passwordNotMatch" class="text-danger">
        New Passwords does not match
      </small>

      <button
        :disabled="!changeReady || changing"
        @click="changePassword"
        class="btn mt-5 w-100"
      >
        <span>Change Password</span>
        <span
          v-if="changing"
          class="spinner-border spinner-border-sm text-light ms-3"
          role="status"
        ></span>
      </button>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import { secureAxios } from "../../services/AxiosInstance";
export default {
  name: "ChangePassword",
  data() {
    return {
      changing: false,
      form: {
        oldPassword: null,
        newPassword: null,
        cpassword: null,
      },
    };
  },
  computed: {
    ...mapGetters(["userEmail"]),

    changeReady() {
      return (
        this.form.oldPassword &&
        this.form.newPassword &&
        this.form.cpassword &&
        this.passwordReady
      );
    },

    passwordReady() {
      return (
        this.form.newPassword &&
        this.form.newPassword.length >= 6 &&
        this.form.oldPassword &&
        this.form.oldPassword.length >= 6 &&
        this.form.cpassword &&
        this.form.cpassword.length >= 6 &&
        !this.passwordNotMatch
      );
    },

    passwordNotMatch() {
      return (
        this.form.newPassword && this.form.newPassword != this.form.cpassword
      );
    },
  },

  methods: {
    async changePassword() {
      if (!this.changeReady) {
        this.$swal({
          icon: "error",
          text: "Fill all fields",
        });
        return;
      }

      try {
        this.changing = true;
        const body = {
          ...this.form,
          email: this.userEmail,
        };

        const api = "auth/change-password";
        const res = await secureAxios.post(api, body);

        this.changing = false;
        if (!res) {
          return;
        }

        const { data } = res;

        // clear the form
        this.form = {
          oldPassword: null,
          newPassword: null,
          cpassword: null,
        };

        this.$swal({
          icon: "success",
          text: data.message,
        });
        this.$router.push({ path: "/app" });
      } catch (err) {
        console.log(err);
        this.changing = false;
      }
    },
  },
};
</script>
<style scoped>
section {
  min-height: 85vh;
  width: 100%;
  background: #fff;
}
section .coln {
  width: 92%;
  max-width: 425px;
  margin: 0 auto;
}
h4 {
  color: #03435f;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
}
label {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #4f4f4f;
}
input {
  height: 55px;
  color: #0b2238;
  background: #ffffff;
}
input:hover,
input:focus {
  box-shadow: none !important;
  border-color: #165df5;
}
input::placeholder {
  color: #6c7a87 !important;
}
button {
  background: rgba(3, 67, 95, 1);
  color: #ffffff;
  padding: 1rem;
}
button:hover {
  color: #ffffff;
}
</style>
